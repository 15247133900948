#PasswordInput {
  color: #92cee5;
  font-size: 2em;
  text-align: center;
  font-family: "Consolas", monospace;
  width: 65%;
}

input[type="text"],
input[type="text"]:focus {
  background: transparent;
  border: none;
  outline: none;
  outline-width: 0;
}

#PasswordStrengthIndicator {
  text-align: center;
  margin-left: 5rem;
  margin-right: 5rem;
  width: 100%;
  height: 2rem;
  background-color: blueviolet;
}
